
import { Component, Vue, Watch } from "vue-property-decorator";

import * as ethers from "ethers";
import Big from "bignumber.js";
Big.config({ EXPONENTIAL_AT: 256 });

import { getContract, getProvider } from "../contracts/helper";
import { Contract, utils } from "ethers";
import { BigNumber, providers, Transaction } from "ethers";
import { parseUnits } from "ethers/lib/utils";
import feeSetup from "@/lib/fee-setup";

function toEther(v: any, dp = 18) {
    return new Big(v.toString()).div(`1e${dp}`);
}

function swapOutput(input: Big, inputReserve: Big, outputReserve: Big) {
    const afterFee = 100 - 1;
    const x = input.times(afterFee);
    return x.times(outputReserve).div(inputReserve.times(100).plus(x));
}

@Component({})
export default class RISK extends Vue {
    provider: providers.JsonRpcProvider | null = null;
    contract: Contract | null = null;
    ercContract: Contract | null = null;

    myBalance = new Big(0);
    myTTBalance = new Big(0);
    myPower = new Big(0);
    myStakedPower = new Big(0);
    myTTPower = new Big(0);
    myRISKPower = new Big(0);

    liquidity = {
        totalPower: new Big(0),
        ttReserve: new Big(0),
        riskReserve: new Big(0),
    };

    blockNumber = new Big(0);
    emitPerBlock = new Big(0.01);
    tokenPerPower = new Big(0);
    lastInspectedBlock = new Big(0);
    stakedPaid = new Big(0);
    totalStakedPower = new Big(0);

    loading = 0;

    modal = {
        riskAmount: "",
        targetAddress: "",
        ttAmount: "",
    };

    administration = window.location.href.indexOf("showAdministration") >= 0;

    toBig(v: any) {
        return new Big(v);
    }

    beforeDestroy() {
        this.cleanUp();
    }

    cleanUp() {
        console.log(Date.now(), "cleanup listeners");
        this.provider?.removeAllListeners();
        this.contract?.removeAllListeners("Transfer");
        this.contract?.removeAllListeners("LiquidityChange");
        this.contract?.removeAllListeners("Swap");
        this.contract?.removeAllListeners("PowerModified");
        this.contract?.removeAllListeners("StakedPowerModified");
    }

    beforeMount() {
        //
    }

    @Watch("$store.state.selectedAccount")
    async whenAccountChanged() {
        this.cleanUp();

        const account = this.$store.state.selectedAccount;
        const contract = this.contract;

        this.provider?.on("block", (block: any) => {
            this.blockNumber = toEther(block, 0);
        });

        if (contract && this.provider) {
            if (account) {
                this.myBalance = toEther(await contract.balanceOf(account));
                this.myTTBalance = toEther(
                    await this.provider.getBalance(account)
                );

                this.provider.on(
                    account,
                    (balance: BigNumber) => (this.myTTBalance = toEther(balance))
                );

                this.myPower = toEther(await contract.powers(account));
                const staked = await contract.powerStakers(account);

                this.myStakedPower = toEther(staked.amount);
                this.stakedPaid = toEther(staked.paid);

                const totalPower = this.myPower.plus(this.myStakedPower);
                if (totalPower.gt(0)) {
                    const values = await contract.powerValue(
                        totalPower.times(1e18).dp(0, Big.ROUND_DOWN).toString()
                    );

                    this.myTTPower = toEther(values[0]);
                    this.myRISKPower = toEther(values[1]);
                }
            }

            this.emitPerBlock = toEther(await contract.emitPerBlock());
            this.tokenPerPower = toEther(await contract.tokenPerPower(), 21);
            this.lastInspectedBlock = toEther(
                await contract.lastInspectedBlock(),
                0
            );
            this.totalStakedPower = toEther(await contract.totalStakedPower());

            // console.log(
            //     this.tokenPerPower.toString(),
            //     this.lastInspectedBlock.toString(),
            //     this.totalStakedPower.toString(),
            //     this.stakedPaid.toString()
            // );

            console.log(Date.now(), "setup contract listeners");

            contract.on(
                "Transfer",
                async (from: string, to: string, amount: BigNumber) => {
                    if (account && (from == account || to == account)) {
                        this.myBalance = toEther(
                            await contract.balanceOf(account)
                        );
                    }

                    const f = from == contract.address ? "THE CONTRACT" : from;
                    const t = to == contract.address ? "THE CONTRACT" : to;

                    console.log(
                        `TRANSFER ${toEther(amount)} from: ${f} to ${t}`
                    );
                }
            );

            contract.on(
                "Swap",
                (who: string, buy: boolean, tt: BigNumber, risk: BigNumber) => {
                    console.log(
                        "SWAP",
                        buy,
                        toEther(tt).toFormat(),
                        toEther(risk).toFormat()
                    );
                }
            );

            contract.on("LiquidityChange", async () => {
                const liquidity: {
                    totalPower: BigNumber;
                    ttReserve: BigNumber;
                    riskReserve: BigNumber;
                } = await contract.liquidity();

                this.liquidity = {
                    totalPower: toEther(liquidity.totalPower),
                    ttReserve: toEther(liquidity.ttReserve),
                    riskReserve: toEther(liquidity.riskReserve),
                };
            });

            contract.on(
                "PowerModified",
                (
                    who: string,
                    adding: boolean,
                    power: BigNumber,
                    totalPower: BigNumber
                ) => {
                    if (who == account) {
                        const nextPower = toEther(totalPower);
                        if (nextPower.gt(this.myPower)) {
                            console.log(
                                who,
                                "received",
                                toEther(power).toFormat(),
                                "POWER"
                            );
                        } else {
                            console.log(
                                who,
                                "loss",
                                toEther(power).toFormat(),
                                "POWER"
                            );
                        }
                        this.myPower = nextPower;
                    }
                }
            );

            contract.on(
                "StakedPowerModified",
                (
                    who: string,
                    adding: boolean,
                    freePower: BigNumber,
                    totalStaked: BigNumber,
                    paid: BigNumber,
                    tokenPerPower: BigNumber,
                    lastInspectedBlock: BigNumber,
                    totalStakedPower: BigNumber
                ) => {
                    if (who == account) {
                        this.myPower = toEther(freePower);
                        this.myStakedPower = toEther(totalStaked);
                        this.stakedPaid = toEther(paid);
                        this.tokenPerPower = toEther(tokenPerPower, 21);
                        this.lastInspectedBlock = toEther(
                            lastInspectedBlock,
                            0
                        );
                        this.totalStakedPower = toEther(totalStakedPower);
                    }
                }
            );
        }
    }

    getProvider(forMonitoring: boolean) {
        const provider = this.$store.state.selectedProvider;
        return getProvider(provider, forMonitoring);
    }

    async mounted() {
        console.log("mounted");
        const provider = this.getProvider(true);
        const contract = getContract("RISK", provider);

        this.provider = provider;
        this.contract = contract;

        const liquidity: {
            totalPower: BigNumber;
            ttReserve: BigNumber;
            riskReserve: BigNumber;
        } = await contract.liquidity();

        this.liquidity = {
            totalPower: toEther(liquidity.totalPower),
            ttReserve: toEther(liquidity.ttReserve),
            riskReserve: toEther(liquidity.riskReserve),
        };

        this.whenAccountChanged();
    }

    async initSwap() {
        try {
            const provider = this.getProvider(false);
            const signer = provider.getSigner();
            const account = await signer.getAddress();
            const balance = toEther(await provider.getBalance(account));
            const ttV = prompt(
                `you have ${balance
                    .dp(2, Big.ROUND_DOWN)
                    .toFormat()} TT, how many TT would you like to init?`,
                "10"
            );

            if (!ttV) return;

            const riskV = prompt(
                `you have ${this.myBalance
                    .dp(2, Big.ROUND_DOWN)
                    .toFormat()} RISK, how many RISK would you like to init?`,
                "10"
            );

            if (!riskV) return;

            if (ttV && riskV) {
                const tt = new Big(ttV).times(1e18).dp(0, Big.ROUND_DOWN);
                const risk = new Big(riskV).times(1e18).dp(0, Big.ROUND_DOWN);

                const contract = getContract("RISK", signer);

                const allowance = toEther(
                    await contract.allowance(account, contract.address)
                );

                let approving = false;

                if (allowance.lt(riskV)) {
                    console.log("approving");
                    approving = true;
                    alert("please allow the contract to spend your RISK");

                    const t = await contract.approve(
                        contract.address,
                        risk.times(22e22).toString(), {
                        ...feeSetup()
                    }
                    );
                    await t.wait(1);
                    console.log("approved!");
                }

                if (approving) {
                    alert("please confirm the tansaction");
                }

                const t = await contract.initSwap(
                    risk.toString(),
                    {
                        value: ethers.BigNumber.from(tt.toString()),
                        ...feeSetup()
                    }
                );

                const r = await t.wait(1);

                if (r.status) {
                    alert("OK!");
                }
            }
        } catch (error) {
            alert(error.message);
        }
    }

    initBuyPower() {
        this.modal.ttAmount = "";
        (window as any).$("#buy-power").modal("show");
    }

    estimatePowerOfBuyPower() {
        const amount = new Big(this.modal.ttAmount);
        if (amount.gt(0) && this.liquidity.ttReserve) {
            // first we buy risk with half of that fund..
            const riskGained = swapOutput(
                amount.div(2),
                this.liquidity.ttReserve,
                this.liquidity.riskReserve
            );
            const ttReserveAfterSwap = this.liquidity.ttReserve.plus(
                amount.div(2)
            );
            const riskReserveAfterSwap =
                this.liquidity.riskReserve.minus(riskGained);

            // tt/risk = ttr/riskr
            // tt = risk * ttr / riskr;
            // risk = tt * riskr / ttr;
            let requiredTT = riskGained
                .times(ttReserveAfterSwap)
                .div(riskReserveAfterSwap);
            let requiredRisk = amount
                .div(2)
                .times(riskReserveAfterSwap)
                .div(ttReserveAfterSwap);

            if (requiredTT.gt(amount.div(2))) {
                requiredTT = amount.div(2);
                // reduce required risk
                requiredRisk = requiredTT
                    .times(riskReserveAfterSwap)
                    .div(ttReserveAfterSwap);
            } else if (requiredRisk.gt(riskGained)) {
                requiredRisk = riskGained;
                // reduce required tt
                requiredTT = requiredRisk
                    .times(ttReserveAfterSwap)
                    .div(riskReserveAfterSwap);
            }

            return requiredTT
                .div(ttReserveAfterSwap)
                .times(this.liquidity.totalPower);
        }
        return new Big(0);
    }

    buyPower() {
        this.loading++;
        this.$nextTick(async function () {
            try {
                const provider = this.getProvider(false);
                const signer = provider.getSigner();
                const account = await signer.getAddress();
                const balance = toEther(await provider.getBalance(account));
                const ttV = this.modal.ttAmount;

                if (!ttV) return;

                if (ttV) {
                    const tt = new Big(ttV).times(1e18).dp(0, Big.ROUND_DOWN);
                    const contract = getContract("RISK", signer);

                    const minReceive = swapOutput(
                        new Big(ttV),
                        this.liquidity.ttReserve,
                        this.liquidity.riskReserve
                    ).times(0.95);

                    const t = await contract.buy(
                        minReceive.times(1e8).dp(0, Big.ROUND_UP).toString(),
                        {
                            value: ethers.BigNumber.from(tt.toString()),
                            ...feeSetup()
                        }
                    );

                    const r = await t.wait(1);

                    const powerModifieds = r.events.filter(
                        (v: any) => v.event == "PowerModified"
                    );

                    for (const p of powerModifieds) {
                        alert(
                            `Received ${toEther(p.args[2])
                                .dp(5)
                                .toFormat()} POWER`
                        );
                    }

                    const ttReturned = r.events.filter(
                        (v: any) => v.event == "TTReturned"
                    );
                    for (const p of ttReturned) {
                        alert(
                            `Returned ${toEther(p.args[1]).dp(5).toFormat()} TT`
                        );
                    }

                    const riskReturned = r.events.filter(
                        (v: any) => v.event == "RiskReturned"
                    );
                    for (const p of riskReturned) {
                        alert(
                            `Returned ${toEther(p.args[1])
                                .dp(5)
                                .toFormat()} RISK`
                        );
                    }

                    (window as any).$("#buy-power").modal("hide");
                }
            } catch (error) {
                alert(error.message);
            } finally {
                --this.loading;
            }
        });
    }

    pendingHarvest() {
        if (!this.totalStakedPower.gt(0)) return new Big(0);

        const n = this.blockNumber.minus(this.lastInspectedBlock);
        const reward = this.emitPerBlock.times(n);

        const tokenPerPower = this.tokenPerPower.plus(
            reward.div(this.totalStakedPower)
        );

        return this.myStakedPower.times(tokenPerPower).minus(this.stakedPaid);
    }

    async harvest() {
        try {
            const provider = this.getProvider(false);
            const signer = provider.getSigner();
            const account = await signer.getAddress();

            const contract = getContract("RISK", signer);

            const t = await contract.stakePower(0, {
                ...feeSetup()
            });

            const r = await t.wait(1);

            if (r.status) {
                if (r.events) {
                    console.log(r.events);
                }
            }
        } catch (error) {
            alert(error.message);
        }
    }

    stakePower() {
        ++this.loading;
        this.$nextTick(async function () {
            try {
                const provider = this.getProvider(false);
                const signer = provider.getSigner();
                const account = await signer.getAddress();

                const contract = getContract("RISK", signer);

                const freePower = toEther(await contract.powers(account));

                if (freePower.gt(0)) {
                    console.log(`staking ${freePower.toFormat()}`);
                    const t = await contract.stakePower(
                        freePower.times(1e18).dp(0, Big.ROUND_DOWN).toString(),
                        {
                            ...feeSetup()
                        }
                    );
                    const r = await t.wait(1);
                    if (r.status) {
                        if (r.events) {
                            console.log(r.events);
                        }
                    }
                } else {
                    alert("could not detect power balance.");
                }
            } catch (error) {
                alert(error.message);
            } finally {
                --this.loading;
            }
        });
    }

    unstakePower() {
        ++this.loading;
        this.$nextTick(async function () {
            try {
                const provider = this.getProvider(false);
                const signer = provider.getSigner();
                const account = await signer.getAddress();

                const contract = getContract("RISK", signer);

                const staker = await contract.powerStakers(account);

                const stakedPower = toEther(staker.amount);

                if (stakedPower.gt(0)) {
                    console.log(`unstaking ${stakedPower.toFormat()}`);
                    const t = await contract.unstakePower(
                        stakedPower
                            .times(1e18)
                            .dp(0, Big.ROUND_DOWN)
                            .toString(),
                        {
                            ...feeSetup()
                        }
                    );
                    const r = await t.wait(1);
                    if (r.status) {
                        if (r.events) {
                            console.log(r.events);
                        }
                    }
                } else {
                    alert("could not detect power balance.");
                }
            } catch (error) {
                alert(error.message);
            } finally {
                --this.loading;
            }
        });
    }

    getPower() {
        ++this.loading;

        this.$nextTick(async function () {
            try {
                const provider = this.getProvider(false);
                const signer = provider.getSigner();
                const account = await signer.getAddress();

                const contract = getContract("RISK", signer);

                const liquidity = await contract.liquidity();

                const ttReserve = toEther(liquidity.ttReserve);
                const riskReserve = toEther(liquidity.riskReserve);

                const rV = prompt(
                    `you have ${this.myBalance} RISK, how many RISK would you like to add to the pool?`,
                    "10"
                );

                if (rV) {
                    const risk = toEther(rV, 0);
                    // calculate TT required..
                    // tt/risk = rtt/rrisk -> tt = risk*rtt/rrisk
                    const tt = risk
                        .times(ttReserve)
                        .div(riskReserve)
                        .dp(18, Big.ROUND_UP);

                    const userTTBalance = toEther(
                        await provider.getBalance(account)
                    );

                    if (userTTBalance.lt(tt)) {
                        return alert(
                            `SORRY, you dont have enough fund to get POWER from RISK+TT, you need ${tt
                                .dp(5, 1)
                                .toFormat()} TT.\nYou currently have ${userTTBalance
                                    .dp(5, 1)
                                    .toFormat()} TT`
                        );
                    }

                    const t = await contract.getPower(
                        risk.times(1e18).dp(0, Big.ROUND_DOWN).toString(),
                        {
                            value: ethers.utils.parseEther(tt.toString()),
                            ...feeSetup()
                        }
                    );

                    const r = await t.wait(1);
                    const powerModifieds = r.events.filter(
                        (v: any) => v.event == "PowerModified"
                    );

                    for (const p of powerModifieds) {
                        alert(
                            `Received ${toEther(p.args[2])
                                .dp(5)
                                .toFormat()} POWER`
                        );
                    }

                    const ttReturned = r.events.filter(
                        (v: any) => v.event == "TTReturned"
                    );
                    for (const p of ttReturned) {
                        alert(
                            `Returned ${toEther(p.args[1]).dp(5).toFormat()} TT`
                        );
                    }

                    const riskReturned = r.events.filter(
                        (v: any) => v.event == "RiskReturned"
                    );
                    for (const p of riskReturned) {
                        alert(
                            `Returned ${toEther(p.args[1])
                                .dp(5)
                                .toFormat()} RISK`
                        );
                    }
                }
            } catch (error) {
                alert(error.message);
            } finally {
                --this.loading;
            }
        });
    }

    async releasePower() {
        try {
            const provider = this.getProvider(false);
            const signer = provider.getSigner();
            const account = await signer.getAddress();

            const contract = getContract("RISK", signer);

            const freePower = toEther(await contract.powers(account));
            const liquidity = await contract.liquidity();

            if (freePower.gt(0)) {
                console.log(`unstaking ${freePower.toFormat()} power`);
                console.log(
                    `total power ${toEther(
                        await liquidity.totalPower
                    ).toFormat()}`
                );
                console.log(
                    `ttReserver ${toEther(
                        await liquidity.ttReserve
                    ).toFormat()}`
                );
                console.log(
                    `riskReserve ${toEther(
                        await liquidity.riskReserve
                    ).toFormat()}`
                );

                const powerValue = await contract.powerValue(
                    ethers.utils.parseEther(freePower.toString())
                );

                console.log(
                    `power value: tt: ${toEther(
                        powerValue[0]
                    )}, risk: ${toEther(powerValue[1])}`
                );

                console.log(
                    `contract tt balance: ${toEther(
                        await provider.getBalance(contract.address)
                    )}`
                );
                console.log(
                    `contract risk balance: ${toEther(
                        await contract.balanceOf(contract.address)
                    )}`
                );

                const t = await contract.burnPower(
                    freePower.times(1e18).dp(0, Big.ROUND_DOWN).toString(),
                    {
                        ...feeSetup()
                    }
                );
                const r = await t.wait(1);
                if (r.status) {
                    if (r.events) {
                        console.log(r.events);
                    }
                }
            } else {
                alert(
                    "power balance is not detected. Please wait for network confirmation.."
                );
            }
        } catch (error) {
            alert(error.message);
        }
    }

    async initSellRisk() {
        this.modal.riskAmount = "0";
        const win = window as any;
        win.$("#swap-to-tt").modal("show");
    }

    getTTOutOfSellRisk() {
        const risk = new Big(this.modal.riskAmount);
        if (risk.gt(0) && this.liquidity.riskReserve.gt(0)) {
            return swapOutput(
                risk,
                this.liquidity.riskReserve,
                this.liquidity.ttReserve
            );
        }

        return new Big(0);
    }

    sellRisk() {
        ++this.loading;

        this.$nextTick(async function () {
            try {
                const provider = this.getProvider(false);
                const signer = provider.getSigner();
                const account = await signer.getAddress();

                const contract = getContract("RISK", signer);

                const v = this.modal.riskAmount;

                if (v) {
                    const allowance = toEther(
                        await contract.allowance(account, contract.address)
                    );

                    let approving = false;

                    if (allowance.lt(v)) {
                        alert("please allow the contract to spend your RISK");
                        approving = true;

                        const t = await contract.approve(
                            contract.address,
                            toEther(v, 0)
                                .times(1e18)
                                .times(22e22)
                                .dp(0)
                                .toString(),
                            {
                                ...feeSetup()
                            }
                        );
                        await t.wait(1);
                        console.log("approved");
                    } else {
                        console.log("already approved");
                    }

                    if (approving) alert("please confirm the swap");

                    const minReceive = swapOutput(
                        new Big(v),
                        this.liquidity.riskReserve,
                        this.liquidity.ttReserve
                    ).times(0.95);

                    const t = await contract.sell(
                        ethers.utils.parseEther(v),
                        minReceive.times(1e18).dp(0, Big.ROUND_UP).toString(),
                        {
                            ...feeSetup()
                        }
                    );

                    const r = await t.wait(1);

                    const win = window as any;
                    win.$("#swap-to-tt").modal("hide");

                    const swaps = r.events.filter(
                        (v: any) => v.event == "Swap"
                    );
                    for (const swap of swaps) {
                        if (swap.buy) {
                            alert(
                                `Swapped\n${toEther(
                                    swap.args.tt
                                ).toFormat()} TT\nto\n${toEther(
                                    swap.args.risk
                                ).toFormat()} RISK`
                            );
                        } else {
                            alert(
                                `Swapped\n${toEther(
                                    swap.args.risk
                                ).toFormat()} RISK\nto\n${toEther(
                                    swap.args.tt
                                ).toFormat()} TT`
                            );
                        }
                    }
                }
            } catch (error) {
                alert(error.message);
            } finally {
                --this.loading;
            }
        });
    }

    initTransferRisk() {
        this.modal.riskAmount = "";
        this.modal.targetAddress = "";
        const win = window as any;
        win.$("#transfer-risk").modal("show");
    }

    transferRisk() {
        ++this.loading;
        this.$nextTick(async function () {
            try {
                const provider = this.getProvider(false);
                const signer = provider.getSigner();
                const account = await signer.getAddress();

                const contract = getContract("RISK", signer);

                const target = ethers.utils.getAddress(
                    this.modal.targetAddress
                );
                const amount = this.modal.riskAmount;

                if (target && amount) {
                    const t = await contract.transfer(
                        target,
                        ethers.utils.parseEther(amount),
                        {
                            ...feeSetup()
                        }
                    );

                    const r = await t.wait(1);

                    const win = window as any;
                    win.$("#transfer-risk").modal("hide");

                    const transfers = r.events.filter(
                        (v: any) => v.event == "Transfer"
                    );

                    for (const transfer of transfers) {
                        alert(
                            `transfered\n${toEther(
                                transfer.args[2]
                            ).toFormat()} RISK\nto\n${transfer.args[1]
                            }\nTxHash: ${t.hash}`
                        );
                    }
                }
            } catch (error) {
                alert(error.message);
            } finally {
                --this.loading;
            }
        });
    }

    apy() {
        const totalTT = this.liquidity.ttReserve.times(2);

        if (totalTT.gt(0)) {
            // RISK generated in one year..
            const oneYear = this.emitPerBlock.times(86400).times(365);
            // tt price
            const ttPerRisk = this.liquidity.ttReserve.div(
                this.liquidity.riskReserve
            );

            const interest = ttPerRisk.times(oneYear).div(totalTT).times(100);

            return interest;
        }

        return new Big(0);
    }

    async ownerMint() {
        try {
            const provider = this.getProvider(false);
            const signer = provider.getSigner();
            const account = await signer.getAddress();

            const contract = getContract("RISK", signer);

            const t = await contract.mint(
                account,
                ethers.utils.parseEther("8000"),
                { ...feeSetup() }
            );
            await t.wait(1);

            alert("ok!");
        } catch (error) {
            console.log(error);
            alert(error.message);
        }
    }
}
